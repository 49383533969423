import React from 'react';
// import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { startSession } from '@futures-platform/connectors/session'
import {DataProvider} from "./store/GlobalState";
import { createRoot } from 'react-dom/client'

import { GlobalStyles } from '@futures-platform/ui'

import 'react-select/dist/react-select.css'
import 'react-quill/dist/quill.snow.css'
import 'rc-slider/dist/rc-slider.css' 
const renderApp = (
    votingURL,
    page,
    position,
    groupRadarId
  ) => {
    const params = !!votingURL && votingURL?.split('/')
    if (!!params) {
        if (params[3] === null || params[3] === 'null') {
            params[3] = null
        }
            return (
                <React.StrictMode>
                    <GlobalStyles />
                    <DataProvider gid={params[2]} rid={params[3]} pid={params[4]}>
                        <App  
                            gid={params[2]}
                            rid={params[3]}
                            pid={params[4]}
                            page={page}
                            position={position}
                            groupRadarId={groupRadarId}
                        />
                    </DataProvider>
                </React.StrictMode>
            )
    }
  }

startSession().then(() => {
    const appElements = document.getElementsByClassName("phen-card-app")
    
    // const defaultRadarId = (/node=\d+/.test(document.location.href) && document.location.href.replace(/^.*node=(\d+).*$/, '$1')) || null

    for (let el of appElements) {
        if (!!el.hasAttribute('data-uri')) {
            const root = createRoot(el);
            root.render(renderApp(
                el.getAttribute('data-uri')
            ));
        }
    }
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
