import { modalStyles, paddingModalStyles } from '@futures-platform/ui'

export const PhenEditCardStyles = {
    content: {
      ...paddingModalStyles.content,
      // margin: 0,
      // background: "white",
      // height: '100%',
      // width: '40%'
    },
    overlay: {
      ...paddingModalStyles.overlay
    }
  }