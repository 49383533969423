import React from "react";
import { isEmpty } from "../../helpers/utils";
import styles from './YoutubeImageEmbed.module.css'
import {getSessionToken} from '@futures-platform/connectors/session'

const YoutubeImageEmbed = ({ embedId, phenomenon }) => {
const publicURL = process.env.REACT_APP_PUBLIC_URL
const isEmbedRadar = (
    !!window 
    && !!window.location 
    && !!window.location.ancestorOrigins?.length 
) ? true : false

    return (
        (phenomenon && !isEmpty(phenomenon)) ? (
            <>
                {phenomenon?.media?.video && <div className={styles.videoIframeWrapper} >
                     <iframe
                        className={styles.iframeForVideo}
                        width="853"
                        height="480"
                        src={phenomenon?.media?.video}

                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                </div>}
                {!!phenomenon?.media?.text &&<p className={styles.mediaText}>{phenomenon?.media?.text}</p>}
                {!!phenomenon?.media?.image && <img className={styles.mediaImage} src={!isEmbedRadar ? publicURL+phenomenon?.media?.image : publicURL+phenomenon?.media?.image + `?access_token=${getSessionToken()}`} alt="nature" loading="lazy"/>}
            </>
        ) :null
    );
}
export default YoutubeImageEmbed;