import { PUBLIC_URL } from '../env'

export const getPhenomenonUrl = (radarId = false, phenomenon, hideEdit = false) => {
  if(!!phenomenon && !!phenomenon?.groups && !!phenomenon?.groups?.length && !phenomenon?.group) {
      // phenomenon['group'] = phenomenon?.radarGroupId || phenomenon?.groups[0]
      phenomenon['group'] = phenomenon?.groups[0]
      
  }
  const { group, id } = phenomenon
  const hasGroup = phenomenon.hasOwnProperty('group')
  const groupUrl = hasGroup ? `group=${group}` : ''

  if (!radarId) {
      return `${PUBLIC_URL}/fp-phenomena/${id}${groupUrl.length ? `/?${groupUrl}` : ''}`
  }

  const urlParams = new URLSearchParams(window.location.search)
  const vsid = urlParams.get('vsid')

  // eslint-disable-next-line
  return `${PUBLIC_URL}/fp-phenomena/${id}${groupUrl.length ? `/?${groupUrl}` : ''}&radar=${radarId}&source_position=right&source_page=radar-view${groupUrl.length ? `&${groupUrl}` : ''}${hideEdit ? '&hideEdit=true' : ''}${vsid ? `&vsid=${vsid}` : ''}`
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
export function normalisedTimeFormat(originalTime, time=false) {
      if (!!originalTime) {
        const originalTimeSplited = originalTime?.split('-')
        const year = originalTimeSplited[0]
        const month = originalTimeSplited[1]
        const day = originalTimeSplited[2].substring(0,2)
        const hour = originalTimeSplited[2].substring(3,8)

        if(!time) {
        return day + '.' + month + '.'  + year
            
        } else {
          return day + '.' + month + '.' + year +  ' ' + hour
        }
      }
   
  }