import React from "react";
// import { getPhenomenaTypes } from '@futures-platform/connectors/drupal-api'
import { isEmpty } from "../../helpers/utils";
import { finalTranslations } from "../../localTranslation";
// import { phenomenonColor } from "../../helpers/phenomenonColor";
import { getPhenomenonUrl } from "../../helpers/contentCard";
import styles from './RelatedPhenomena.module.css'
import { ContentCardType } from "../ContentCardType";

const RelatedPhenomena = ({ phenomenon, gid, rid, pid, lang }) => {
  // const [phenomenaTypesData, setPhenomenaTypesData] = useState(null);
  // useEffect(() => {
  //   const fetchPhenomenaTypes = async () => {
  //     const [ phenomenaTypes] = await Promise.all(
  //       [
  //         getPhenomenaTypes(gid || 0),
          
  //       ]
  //     )
      
      
  //     setPhenomenaTypesData(phenomenaTypes)

  //   }
  //   try {
  //     gid && rid && pid && fetchPhenomenaTypes()
  //   } catch (error) {
      
  //   }
  // }, [gid, rid, pid])

  // console.log('phenomenaTypesDataphenomenaTypesData', phenomenaTypesData)
  return (
        !!phenomenon && !isEmpty(phenomenon) && phenomenon["related-phenomena-data"] && phenomenon["related-phenomena-data"].length > 0 ? (
      <div className={styles.relatedPhenomenaWrapper}>
        <h2 className={styles.h2Title}>{lang === 'fi' ? finalTranslations?.relatedPhenomenaSection?.fi : finalTranslations?.relatedPhenomenaSection?.en}</h2>
        <div className="">
          {!!phenomenon && !!phenomenon['related-phenomena-data']?.length && phenomenon['related-phenomena-data']?.map(phe => {
              // phenomenaTypesData && phenomenaTypesData?.map(type => {
              //   if(type.id === phe.content.type) {
              //     phe['content-type-alias'] = type.alias;
              //     phe['content-type-title'] = type.title; 
              //     phe['color'] = phe.content.type.includes('fp:doc-types') ? String(type.style.color): 'none'
              //   }
              // })
              // const {iconClassName, backgroundColor} = phenomenonColor(phe);
              const isFpGroup = Number(phe?.group) === 0 || (!!phe?.groups?.length && Number(phe?.groups[0]) === 0)
              // phe['radarGroupId'] = Number(gid)
              return (
                <a
                  // href="#abc"
                  rel="noreferrer noopener"
                  target="_blank"
                  className={styles.phenLink}
                  key={phe?.id}
                >
                  <div 
                    className={`content-card right ${styles.phenWrapper}`}
                    data-href={getPhenomenonUrl(rid, phe)}
                    style={{cursor: 'pointer'}}
                    data-position="right"
                  >
                    {/* <div className={`icon-issue ${iconClassName}`} style={{marginTop: '1px'}}></div> */}
                    <ContentCardType phenomenon={phe} isFpGroup={isFpGroup} />
                  </div>
                 
                  <h4 
                    className={`content-card right ${styles.phenName}`}
                    data-href={getPhenomenonUrl(rid, phe)}
                    style={{cursor: 'pointer'}}
                    data-position="right"
                  >{phe?.content?.title}</h4>
                </a>
              )
          })}
        </div>
      </div>
    ) : null
  );
};

export default RelatedPhenomena;
