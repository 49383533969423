import  axios from 'axios'
import { getSessionToken, getCsrfToken } from "@futures-platform/connectors/session"

const baseUrl = process.env.REACT_APP_VOTING_BASE_URL_API
const publicURL = process.env.REACT_APP_PUBLIC_URL
const DRUPAL_API_URL = process.env.REACT_APP_DRUPAL_API_URL
 
// async function httpRequest(method, path, payload = null) {
//   console.log('`${baseUrl}/${path}`', `${baseUrl}/${path}`)
//   return axios({
//       method,
//       url: `${baseUrl}/${path}`,
//       headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${getSessionToken()}`
//       },
//       withCredentials: true,
//       data: payload || null
//   })
// }

async function httpRequest(urlParam, method, path, payload = null, headers={}) {
  return axios({
      method,
      url: `${urlParam}/${path}`,
      headers: {
          'X-CSRF-Token': getCsrfToken(),
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getSessionToken()}`,
          ...headers
      },
      withCredentials: true,
      data: payload || null
  })
}

// url: `${DRUPAL_API_URL}/v1.0/${path}`,
export const votingApi = {
  storeImgToDb: async (gid, imgFile) => {
    return await httpRequest(publicURL, 'PUT', !!gid ? `file/${gid}/${imgFile?.name}` :`file/${imgFile?.name}`, imgFile, {
        'Content-Type': imgFile?.type || 'application/octet-stream',
    })
  },
  
  getUserName: async (userId) => {
    return await httpRequest(`${DRUPAL_API_URL}`, 'GET', `v1.0/user/${userId}`)
  },
  
  //Voting
  //get radar phenomenon vote for current user
  getVote: async (gid, radarId, pid) => {
    if(!gid || gid === 'NaN' || gid === undefined) return null
    return await httpRequest(baseUrl, 'GET', `voting/${gid}/radar/${radarId}/phenomenon/${pid}/user`)
  },

  //get all radar phenomenon votes
  getVotes: async (gid, radarId, pid) => {
    if(!gid || gid === 'NaN' || gid === undefined) return null
    return await httpRequest(baseUrl, 'GET', `voting/${gid}/radar/${radarId}/phenomenon/${pid}`)
  },

  //vote on radar phenomenon as current user
  addVote: async (gid, radarId, pid, payload) => {
    if(!gid || gid === 'NaN' || gid === undefined) return null
    return await httpRequest(baseUrl, 'POST', `voting/${gid}/radar/${radarId}/phenomenon/${pid}`, payload)
  },
  
  // cancel radar phenomenon vote for current user
  deleteVote: async (gid, radarId, pid) => {
    if(!gid || gid === 'NaN' || gid === undefined) return null
    return await httpRequest(baseUrl, 'DELETE', `voting/${gid}/radar/${radarId}/phenomenon/${pid}/user`)
  },

  //ThumbUp 
  //get radar phenomenon comment vote for current user
  getLike: async (gid, rid, pid, cid) => {
    if(!gid || gid === 'NaN' || gid === undefined) return null
    return await httpRequest(baseUrl, 'GET', `voting/${gid}/radar/${rid}/phenomenon/${pid}/comment/${cid}/user`)
  },

  //get all radar phenomenon comment votes
  getLikes: async (gid, rid, pid, cid) => {
    if(!gid || gid === 'NaN' || gid === undefined) return null
    return await httpRequest(baseUrl, 'GET', `voting/${gid}/radar/${rid}/phenomenon/${pid}/comment/${cid}`)
  },

  //vote on radar phenomenon comment as current user
  addLike: async (gid, rid, pid, cid, payload) => {
    if(!gid || gid === 'NaN' || gid === undefined) return null
    return await httpRequest(baseUrl, 'POST', `voting/${gid}/radar/${rid}/phenomenon/${pid}/comment/${cid}`, payload)
  },

  //get all avg radar phenomenon ratings
  getPhenomenonRatings: async (gid, radarId, pid) => {
    if(!gid || gid === 'NaN' || gid === undefined) return null
    return await httpRequest(baseUrl, 'GET', `rating/${gid}/radar/${radarId}/phenomenon/${pid}`)
  },

  //Rating
  //get avg radar phenomenon rating for current user
  getRatingsCurrentUser: async (gid, radarId, pid) => {
    if(!gid || gid === 'NaN' || gid === undefined) return null
      return await httpRequest(baseUrl, 'GET', `rating/${gid}/radar/${radarId}/phenomenon/${pid}/user`)
  },
  //rate on radar phenomenon as current user
  addRatingCurrentUser: async (gid, radarId, pid, ratingId, payload) => {
    if(!gid || gid === 'NaN' || gid === undefined) return null
      return await httpRequest(baseUrl, 'POST', `rating/${gid}/radar/${radarId}/phenomenon/${pid}/${ratingId}`, payload)
  },

  //clear radar phenomenon ratings for current user
  clearRatingsCurrentUser: async (gid, radarId, pid) => {
    if(!gid || gid === 'NaN' || gid === undefined) return null
      return await httpRequest(baseUrl, 'DELETE', `rating/${gid}/radar/${radarId}/phenomenon/${pid}/user`)
  },

  getFlipAxisAfterSaved : async (gid, radarId) => {
    if(!gid || gid === 'NaN' || gid === undefined) return null
      return await httpRequest(baseUrl, 'GET', `meta/rating/${gid}/radar/${radarId}/flipAxisAfterSaved/`)
  }
}