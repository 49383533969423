import React from 'react'
export const ContentCardType = ({ phenomenon, isFpGroup }) => {
  try {
    if (String(phenomenon?.["color"]) === "none") {
      if (phenomenon?.["content-type-alias"] === "rising" && !isFpGroup) {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="8" cy="8" r="8" fill="#00CA8D" />
          </svg>
        );
      } else if (
        phenomenon?.["content-type-alias"] === "weaksignal" &&
        !isFpGroup
      ) {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="8" cy="8" r="8" fill="#A8A8A8" />
          </svg>
        );
      } else if (
        phenomenon?.["content-type-alias"] === "summary" &&
        !isFpGroup
      ) {
        return (
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="10.5" cy="10.5" r="10.5" fill="#00CA8D" />
            <path
              d="M10.5 18.5C14.9183 18.5 18.5 14.9183 18.5 10.5C18.5 6.08172 14.9183 2.5 10.5 2.5C6.08172 2.5 2.5 6.08172 2.5 10.5C2.5 14.9183 6.08172 18.5 10.5 18.5Z"
              fill="#00CA8D"
              stroke="white"
            />
          </svg>
        );
      } else if (
        phenomenon?.["content-type-alias"] === "cooling" &&
        !isFpGroup
      ) {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="8" cy="8" r="8" fill="#0098FF" />
          </svg>
        );
      } else if (
        phenomenon?.["content-type-alias"] === "wildcard" &&
        !isFpGroup
      ) {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="8" cy="8" r="8" fill="#E95757" />
          </svg>
        );
      } else if (
        phenomenon?.["content-type-alias"] === "undefined" &&
        !isFpGroup
      ) {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="8" cy="8" r="7" fill="#00CA8D" />
            <circle cx="8" cy="8" r="5.25" fill="white" />
          </svg>
        );
      } else if (
        phenomenon?.["content-type-alias"] === "rising" &&
        !!isFpGroup
      ) {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="8" cy="8" r="8" fill="#00CA8D" />
            <g opacity="0.4">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.39548 11.3009C8.68303 11.276 8.54793 9.51962 8.56005 7.87303C8.57394 6.11953 8.80896 5.3844 9.28018 5.12783C9.62163 4.94156 10.7636 5.01273 10.8189 5.01624C12.3857 5.11406 13 6.16142 13 7.13057C13 9.04749 11.8787 9.21063 10.9247 9.80489C10.1535 10.2852 10.113 11.3009 9.39548 11.3009Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.91364 5.70345C7.90684 5.16631 7.52282 5.07522 7.08708 5.03656C6.68917 5.00053 5.47623 4.9774 4.9175 5.06292C4.13824 5.18329 4.02413 6.49336 4.00255 7.92937C3.9789 9.53818 4.10986 11.2551 4.81078 11.2996C4.81522 11.2996 4.81965 11.3005 4.82379 11.3005C5.0665 11.309 5.23441 11.2026 5.37394 11.0421C5.57851 10.807 5.71982 10.454 5.94863 10.1726C6.36694 9.65914 6.65281 9.52119 7.09742 9.1466C7.77499 8.57577 7.43354 7.87109 7.44833 7.33277C7.45956 6.93884 7.58697 6.67964 7.64314 6.56717C7.83825 6.1747 7.9163 5.92575 7.91364 5.70345Z"
                fill="white"
              />
            </g>
          </svg>
        );
      } else if (
        phenomenon?.["content-type-alias"] === "weaksignal" &&
        !!isFpGroup
      ) {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="8" cy="8" r="8" fill="#A8A8A8" />
            <g opacity="0.4">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.39548 11.3009C8.68303 11.276 8.54793 9.51962 8.56005 7.87303C8.57394 6.11953 8.80896 5.3844 9.28018 5.12783C9.62163 4.94156 10.7636 5.01273 10.8189 5.01624C12.3857 5.11406 13 6.16142 13 7.13057C13 9.04749 11.8787 9.21063 10.9247 9.80489C10.1535 10.2852 10.113 11.3009 9.39548 11.3009Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.91364 5.70345C7.90684 5.16631 7.52282 5.07522 7.08708 5.03656C6.68917 5.00053 5.47623 4.9774 4.9175 5.06292C4.13824 5.18329 4.02413 6.49336 4.00255 7.92937C3.9789 9.53818 4.10986 11.2551 4.81078 11.2996C4.81522 11.2996 4.81965 11.3005 4.82379 11.3005C5.0665 11.309 5.23441 11.2026 5.37394 11.0421C5.57851 10.807 5.71982 10.454 5.94863 10.1726C6.36694 9.65914 6.65281 9.52119 7.09742 9.1466C7.77499 8.57577 7.43354 7.87109 7.44833 7.33277C7.45956 6.93884 7.58697 6.67964 7.64314 6.56717C7.83825 6.1747 7.9163 5.92575 7.91364 5.70345Z"
                fill="white"
              />
            </g>
          </svg>
        );
      } else if (
        phenomenon?.["content-type-alias"] === "summary" &&
        !!isFpGroup
      ) {
        return (
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="10.5" cy="10.5" r="10.5" fill="#00CA8D" />
            <path
              d="M10.5 18.5C14.9183 18.5 18.5 14.9183 18.5 10.5C18.5 6.08172 14.9183 2.5 10.5 2.5C6.08172 2.5 2.5 6.08172 2.5 10.5C2.5 14.9183 6.08172 18.5 10.5 18.5Z"
              fill="#00CA8D"
              stroke="white"
            />
            <g opacity="0.4">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.3955 13.8009C10.683 13.776 10.5479 12.0196 10.56 10.373C10.5739 8.61953 10.809 7.8844 11.2802 7.62783C11.6216 7.44156 12.7636 7.51273 12.8189 7.51624C14.3857 7.61406 15 8.66142 15 9.63057C15 11.5475 13.8787 11.7106 12.9247 12.3049C12.1535 12.7852 12.113 13.8009 11.3955 13.8009Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.91364 8.20345C9.90684 7.66631 9.52282 7.57522 9.08708 7.53656C8.68917 7.50053 7.47623 7.4774 6.9175 7.56292C6.13824 7.68329 6.02413 8.99336 6.00255 10.4294C5.9789 12.0382 6.10986 13.7551 6.81078 13.7996C6.81522 13.7996 6.81965 13.8005 6.82379 13.8005C7.0665 13.809 7.23441 13.7026 7.37394 13.5421C7.57851 13.307 7.71982 12.954 7.94863 12.6726C8.36694 12.1591 8.65281 12.0212 9.09742 11.6466C9.77499 11.0758 9.43354 10.3711 9.44833 9.83277C9.45956 9.43884 9.58697 9.17964 9.64314 9.06717C9.83825 8.6747 9.9163 8.42575 9.91364 8.20345Z"
                fill="white"
              />
            </g>
          </svg>
        );
      } else if (
        phenomenon?.["content-type-alias"] === "cooling" &&
        !!isFpGroup
      ) {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="8" cy="8" r="8" fill="#0098FF" />
            <g opacity="0.4">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.39548 11.3009C8.68303 11.276 8.54793 9.51962 8.56005 7.87303C8.57394 6.11953 8.80896 5.3844 9.28018 5.12783C9.62163 4.94156 10.7636 5.01273 10.8189 5.01624C12.3857 5.11406 13 6.16142 13 7.13057C13 9.04749 11.8787 9.21063 10.9247 9.80489C10.1535 10.2852 10.113 11.3009 9.39548 11.3009Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.91364 5.70345C7.90684 5.16631 7.52282 5.07522 7.08708 5.03656C6.68917 5.00053 5.47623 4.9774 4.9175 5.06292C4.13824 5.18329 4.02413 6.49336 4.00255 7.92937C3.9789 9.53818 4.10986 11.2551 4.81078 11.2996C4.81522 11.2996 4.81965 11.3005 4.82379 11.3005C5.0665 11.309 5.23441 11.2026 5.37394 11.0421C5.57851 10.807 5.71982 10.454 5.94863 10.1726C6.36694 9.65914 6.65281 9.52119 7.09742 9.1466C7.77499 8.57577 7.43354 7.87109 7.44833 7.33277C7.45956 6.93884 7.58697 6.67964 7.64314 6.56717C7.83825 6.1747 7.9163 5.92575 7.91364 5.70345Z"
                fill="white"
              />
            </g>
          </svg>
        );
      } else if (
        phenomenon?.["content-type-alias"] === "wildcard" &&
        !!isFpGroup
      ) {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="8" cy="8" r="8" fill="#E95757" />
            <g opacity="0.4">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.39548 11.3009C8.68303 11.276 8.54793 9.51962 8.56005 7.87303C8.57394 6.11953 8.80896 5.3844 9.28018 5.12783C9.62163 4.94156 10.7636 5.01273 10.8189 5.01624C12.3857 5.11406 13 6.16142 13 7.13057C13 9.04749 11.8787 9.21063 10.9247 9.80489C10.1535 10.2852 10.113 11.3009 9.39548 11.3009Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.91364 5.70345C7.90684 5.16631 7.52282 5.07522 7.08708 5.03656C6.68917 5.00053 5.47623 4.9774 4.9175 5.06292C4.13824 5.18329 4.02413 6.49336 4.00255 7.92937C3.9789 9.53818 4.10986 11.2551 4.81078 11.2996C4.81522 11.2996 4.81965 11.3005 4.82379 11.3005C5.0665 11.309 5.23441 11.2026 5.37394 11.0421C5.57851 10.807 5.71982 10.454 5.94863 10.1726C6.36694 9.65914 6.65281 9.52119 7.09742 9.1466C7.77499 8.57577 7.43354 7.87109 7.44833 7.33277C7.45956 6.93884 7.58697 6.67964 7.64314 6.56717C7.83825 6.1747 7.9163 5.92575 7.91364 5.70345Z"
                fill="white"
              />
            </g>
          </svg>
        );
      } else if (
        phenomenon?.["content-type-alias"] === "undefined" &&
        !!isFpGroup
      ) {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="8" cy="8" r="7" fill="#00CA8D" />
            <circle cx="8" cy="8" r="5.25" fill="white" />
            <g opacity="0.5">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.22104 10.8883C8.59765 10.8665 8.47944 9.32967 8.49004 7.8889C8.5022 6.35459 8.70784 5.71135 9.12016 5.48685C9.41892 5.32386 10.4182 5.38614 10.4665 5.38921C11.8375 5.47481 12.375 6.39124 12.375 7.23925C12.375 8.91656 11.3939 9.0593 10.5591 9.57928C9.88427 9.99957 9.84883 10.8883 9.22104 10.8883Z"
                fill="#00CA8D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.92443 5.99055C7.91848 5.52055 7.58247 5.44085 7.20119 5.40702C6.85302 5.3755 5.7917 5.35525 5.30281 5.43008C4.62096 5.53541 4.52112 6.68172 4.50223 7.93823C4.48154 9.34594 4.59613 10.8482 5.20943 10.8872C5.21332 10.8872 5.2172 10.8879 5.22082 10.8879C5.43318 10.8954 5.58011 10.8023 5.7022 10.6619C5.8812 10.4561 6.00484 10.1473 6.20505 9.90103C6.57107 9.45178 6.82121 9.33108 7.21024 9.0033C7.80311 8.50383 7.50435 7.88723 7.51729 7.4162C7.52711 7.07151 7.6386 6.84471 7.68775 6.7463C7.85847 6.4029 7.92676 6.18506 7.92443 5.99055Z"
                fill="#00CA8D"
              />
            </g>
          </svg>
        );
      }
      else {
        return null
      }
    } else {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="8" fill={phenomenon?.["color"]} />
        </svg>
      );
      // iconClassName = "undefined";
      // backgroundColor = phenomenon?.["color"];
    }
  } catch (error) {
    console.log(error);
  }
};
