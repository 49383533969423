import {finalTranslations} from '../localTranslation'
import {commentingApi} from './commentingFetcher'
import { getUserId } from '@futures-platform/connectors/session'

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export const getAllCommentsByPhenId = async (gid, rid, pid) => {
  const cmtsRes = await commentingApi.getAllCommentsByPhenId(gid, rid, pid)
      const temp1 = !!cmtsRes?.data ? [...cmtsRes?.data] : []
      !!temp1?.length && temp1?.map((cmt) => {
        if (getUserId().toString() === cmt.uid.toString()) {
            cmt['isAuthor'] = true
          } else {
            cmt['isAuthor'] = false
          }
      })?.data?.sort(function(x, y){
        return x.created_timestamp - y.created_timestamp;
    })
    return cmtsRes
}
export const translatePhenomenonAlias = (phenomenon, lang) => {
  let translationTitle = ""
  if (phenomenon?.["content-type-alias"] === "rising") {
    translationTitle= lang === 'fi' ? finalTranslations?.rising?.fi : finalTranslations?.rising?.en
  } else if (phenomenon?.["content-type-alias"] === "weaksignal") {
    translationTitle = lang === 'fi' ? finalTranslations?.weaksignal?.fi : finalTranslations?.weaksignal?.en
  } else if (phenomenon?.["content-type-alias"] === "summary" || phenomenon?.['content-type-title'] === 'Summary') {
    translationTitle = lang === 'fi' ? finalTranslations?.summary?.fi : finalTranslations?.summary?.en
  } else if (phenomenon?.["content-type-alias"] === "cooling") {
    translationTitle = lang === 'fi' ? finalTranslations?.cooling?.fi : finalTranslations?.cooling?.en
  } else if (phenomenon?.["content-type-alias"] === "wildcard") {
    translationTitle = lang === 'fi' ? finalTranslations?.wildcard?.fi : finalTranslations?.wildcard?.en
  } else {
    translationTitle = lang === 'fi' ? finalTranslations?.undefined?.fi : finalTranslations?.undefined?.en
  }
  
  return {
    translationTitle
  };
};

export const translateCommentSections = (section, lang) => {
  let translationTitle = ""
  if (section === "Actions") {
    translationTitle= lang === 'fi' ? finalTranslations?.actionsCommentSection?.fi : finalTranslations?.actionsCommentSection?.en
  } else if (section === "Opportunities") {
    translationTitle = lang === 'fi' ? finalTranslations?.opportunitiesCommentSection?.fi : finalTranslations?.opportunitiesCommentSection?.en
  } else if (section === "Threats") {
    translationTitle = lang === 'fi' ? finalTranslations?.threatsCommentSection?.fi : finalTranslations?.threatsCommentSection?.en
  }
  return {
    translationTitle
  };
}