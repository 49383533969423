import React from "react";
import { finalTranslations } from "../../localTranslation";
import clsx from "clsx";
import styles from './CardFooter.module.css'
import {normalisedTimeFormat} from '../../helpers/helpers'

const CardFooter = ({
    lang,
    onOpenPhenEditCard,
    canEditContentCard,
    data,
    gid,
    lastesEditor
}) => {
    const EdittedTitle = lang === 'fi' ? finalTranslations?.editted?.fi :finalTranslations?.editted?.en;
    // `27.05.2021`
    const DateEditted = React.useMemo(() => {
        return normalisedTimeFormat((!!data?.getPhenomenaData?.result?.length && data?.getPhenomenaData?.result[0]?.updated_at) 
        || (!!data?.getPhenomenaData?.result?.length && data?.getPhenomenaData.result[0]?.created_at))
    }, [data])
    const UserEditted = React.useMemo(() => {
        if (Number(gid) === 0) {
            return `Futures Platform`
        } else {
            // call user api to get username here
            return lastesEditor
        }
    }, [gid, lastesEditor])

    return (
        <div className={styles.cardFooterWrapper}>
            <p className={styles.footerText}>
                {/* Edited 27.05.2021 by Futures Platform Päivitetty 06.02.2022 - Anton Kupias*/}
                {EdittedTitle}{' '}{DateEditted}{ !!UserEditted ? ' - ' : ''}{UserEditted}
            </p>
            {
                !!canEditContentCard && (
                    <div>
                        <button onClick={onOpenPhenEditCard} className={styles.editPhenomenonBtn}>
                            <span className={clsx(styles.pencilIcon, 'af-custom-edit')}></span>
                            {/* EDIT PHENOMENON */}
                            {lang === 'fi' ? finalTranslations?.editPhenomenon?.fi.toUpperCase() : finalTranslations?.editPhenomenon?.en.toUpperCase()}
                        </button>
                    </div>
                )
            }
        </div>
    );
};

export default CardFooter;
