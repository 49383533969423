import React from "react";
import { Carousel } from "react-responsive-carousel";
import { finalTranslations } from "../../localTranslation";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from './LatestNewsCarousel.module.css'
import {normalisedTimeFormat} from '../../helpers/helpers'

const LatestNews = ({lang, phenNews}) => {

    const phenNewsLimted3 = React.useMemo(() => {
        if(phenNews?.length > 3) {
            return phenNews?.slice(0, 3)
          } 
          return phenNews 
    }, [JSON.stringify(phenNews)])

    return (
        <div className={styles.carouselWrapper}>
            {
                !!phenNewsLimted3?.length && (
                    <h3 className={styles.h3Title}>{lang === 'fi' ? finalTranslations?.latestNewsTitle?.fi : finalTranslations?.latestNewsTitle?.en}</h3>
                )
            }
            <Carousel autoPlay infiniteLoop showArrows={false} showThumbs={false} className={styles.carouselBackground}>
                {
                    !!phenNewsLimted3?.length && phenNewsLimted3?.slice(0, 3)?.map((news) => (
                        <a 
                            className=""
                            style={{cursor: 'pointer'}}
                            href={news?.content?.link} target="_blank" rel="noreferrer"
                        >
                            <p className={styles.textDesc}>{normalisedTimeFormat(news?.updated_at || news?.created_at, true)}</p>
                            <h4 className={styles.h4Title}>
                                {news?.content?.title?.substring(0,150) || news?.content?.summary?.substring(0,150)}
                            </h4>
                        </a>
                    ))
                }
                {/* <a href="https://www.youtube.com/" target="_blank" rel="noreferrer" className="">
                    <p className={styles.textDesc}>Business Wire 11.12.2020 23:04</p>
                    <h4 className={styles.h4Title}>
                        Updated Outcome and Treatment Benefit Data from MINDACT Study
                        Reinforce Real-World Value
                    </h4>
                </a> */}

            </Carousel>
        </div>
    );
};

export default LatestNews;
