import React, { useState, useEffect, useContext } from "react";
import { getPhenomenaTypes, getGroupById, getRadar } from '@futures-platform/connectors/drupal-api'
import { normalizeGroup } from '@futures-platform/connectors/drupal-api/normalize'
import {getPhenomena, getContentCardData, getPhenomenonNewsByPhenId, getPhenomenaStatistic} from './helpers/phenomenonFetcher'
import YoutubeImageEmbed from "./components/YoutubeImageEmbed";
import LatestNewsCarousel from "./components/LatestNewsCarousel";
import Comments from "./components/Comments";
import CardHeader from './components/CardHeader'
import BodyCard from "./components/CardBody";
import RelatedPhenomena from "./components/RelatedPhenomena";
import LatestNews from "./components/LatestNews";
import CardFooter from "./components/CardFooter";
import Links from "./components/Links";
import Rating from './components/Rating/Rating'
import { PhenomenonEditForm } from './components/PhenEditForm/PhenomenonEditForm';
import {
  Modal,
  // paddingModalStyles,
} from "@futures-platform/ui";
import {PhenEditCardStyles} from "./styles"
import {DataContext} from './store/GlobalState'
import { ACTIONS } from './store/Actions'
import useSWR, { SWRConfig } from 'swr'
// import {commentingApi} from './helpers/commentingFetcher'
// import { getUserId } from '@futures-platform/connectors/session'
import {getAllCommentsByPhenId} from './helpers/utils'
import { votingApi } from "./helpers/fetcher";
// import styled from 'styled-components'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const App = ({gid, rid, pid, position, groupRadarId}) => {
  NProgress.configure({ minimum: 0.1 })

  const { state: {tagsCountClicked}, dispatch } = useContext(DataContext)

  const [openPhenEditCard, setOpenPhenEditCard] = useState(null);
  NProgress.start()
  NProgress.set(0.2)
  const groupIdUsed = React.useMemo(() => {
    return groupRadarId || gid
  }, [groupRadarId, gid])
  const {data, mutate: mutateContentCard} = useSWR(`getAllCommentsByPhenId=${groupIdUsed}/${rid}/${pid}` , async() => {
    
    // const groups = [...new Set([0, Number(gid)])]
     try {
      const [phenomenon, phenomenonType, crowdsourcedData, allCmtsData, 
        // newsFeed,
        getPhenomenaData, getGroupByIdData, getRadarData] = await Promise.all(
        [
          getContentCardData(Number(gid) || 0, pid),
          getPhenomenaTypes(gid || 0),
          getPhenomenaStatistic(pid),
          (!!pid && !!rid) && getAllCommentsByPhenId(groupRadarId || gid, rid, pid),
          // getNewsFeedData(gid || 0, pid),
          getPhenomena({'phenomena': [pid], undefined, groups: [Number(gid) || 0]}),
          getGroupById(gid || 0),
          (!!pid && !!rid) && getRadar(rid)
        ]
      )

      dispatch({
        type: ACTIONS.GETRADARDATAACTION,
        payload: getRadarData
      })

      dispatch({
        type: ACTIONS.CMTSDATA,
        payload: allCmtsData?.data
      })
      dispatch({
        type: ACTIONS.GROUPRADARID,
        payload: groupRadarId
      })
      dispatch({
        type: ACTIONS.CONTENTTYPES,
        payload: phenomenonType
      })

      // const a =  [
      //   {
      //     "id": 56,
      //     "label": "FP M1",
      //     "usersAllowed": 300,
      //     "visitorsAllowed": 100,
      //     "radarsAllowed": 100,
      //     "usersUsed": 8,
      //     "radarsUsed": 3,
      //     "radarLogoEnabled": 1,
      //     "accountPermissions": {
      //       "role": "fp-owner",
      //       "permissions": [
      //         "view group",
      //         "view radars page"
      //       ]
      //     },
      //     "accountDrupalRoles": [
      //       [
      //         "authenticated"
      //       ]
      //     ],
      //     "availableResources": {
      //       "plan": "shaper",
      //       "radars": 97,
      //       "members": 292,
      //       "visitors": 98,
      //       "collaborationTools": 1,
      //       "wall": 0
      //     },
      //     "settings": null,
      //     "urlLoginEnabled": 1
      //   },
      //   {
      //     "id": 56,
      //     "label": "FP M1",
      //     "usersAllowed": 300,
      //     "visitorsAllowed": 100,
      //     "radarsAllowed": 100,
      //     "usersUsed": 8,
      //     "radarsUsed": 3,
      //     "radarLogoEnabled": 1,
      //     "accountPermissions": {
      //       "role": "fp-member",
      //       "permissions": [
      //         "view group",
      //         "view radars page"
      //       ]
      //     },
      //     "accountDrupalRoles": [
      //       [
      //         "authenticated"
      //       ]
      //     ],
      //     "availableResources": {
      //       "plan": "shaper",
      //       "radars": 97,
      //       "members": 292,
      //       "visitors": 98,
      //       "collaborationTools": 1,
      //       "wall": 0
      //     },
      //     "settings": null,
      //     "urlLoginEnabled": 1
      //   },
      //   {
      //     "id": 56,
      //     "label": "FP M1",
      //     "usersAllowed": 300,
      //     "visitorsAllowed": 100,
      //     "radarsAllowed": 100,
      //     "usersUsed": 8,
      //     "radarsUsed": 3,
      //     "radarLogoEnabled": 1,
      //     "accountPermissions": {
      //       "role": "fp-member",
      //       "permissions": [
      //         "view group",
      //         "view radars page"
      //       ]
      //     },
      //     "accountDrupalRoles": [
      //       [
      //         "authenticated"
      //       ]
      //     ],
      //     "availableResources": {
      //       "plan": "shaper",
      //       "radars": 97,
      //       "members": 292,
      //       "visitors": 98,
      //       "collaborationTools": 1,
      //       "wall": 0
      //     },
      //     "settings": null,
      //     "urlLoginEnabled": 1
      //   },
      //   {
      //     "id": 56,
      //     "label": "FP M1",
      //     "usersAllowed": 300,
      //     "visitorsAllowed": 100,
      //     "radarsAllowed": 100,
      //     "usersUsed": 8,
      //     "radarsUsed": 3,
      //     "radarLogoEnabled": 1,
      //     "accountPermissions": {
      //       "role": "fp-member",
      //       "permissions": [
      //         "view group",
      //         "view radars page"
      //       ]
      //     },
      //     "accountDrupalRoles": [
      //       [
      //         "authenticated"
      //       ]
      //     ],
      //     "availableResources": {
      //       "plan": "shaper",
      //       "radars": 97,
      //       "members": 292,
      //       "visitors": 98,
      //       "collaborationTools": 1,
      //       "wall": 0
      //     },
      //     "settings": null,
      //     "urlLoginEnabled": 1
      //   },
      //   {
      //     "id": 56,
      //     "label": "FP M1",
      //     "usersAllowed": 300,
      //     "visitorsAllowed": 100,
      //     "radarsAllowed": 100,
      //     "usersUsed": 8,
      //     "radarsUsed": 3,
      //     "radarLogoEnabled": 1,
      //     "accountPermissions": {
      //       "role": "fp-coordinator",
      //       "permissions": [
      //         "view group",
      //         "view radars page"
      //       ]
      //     },
      //     "accountDrupalRoles": [
      //       [
      //         "authenticated"
      //       ]
      //     ],
      //     "availableResources": {
      //       "plan": "shaper",
      //       "radars": 97,
      //       "members": 292,
      //       "visitors": 98,
      //       "collaborationTools": 1,
      //       "wall": 0
      //     },
      //     "settings": null,
      //     "urlLoginEnabled": 1
      //   },
      //   {
      //     "id": 56,
      //     "label": "FP M1",
      //     "usersAllowed": 300,
      //     "visitorsAllowed": 100,
      //     "radarsAllowed": 100,
      //     "usersUsed": 8,
      //     "radarsUsed": 3,
      //     "radarLogoEnabled": 1,
      //     "accountPermissions": {
      //       "role": "fp-coordinator",
      //       "permissions": [
      //         "view group",
      //         "view radars page"
      //       ]
      //     },
      //     "accountDrupalRoles": [
      //       [
      //         "authenticated"
      //       ]
      //     ],
      //     "availableResources": {
      //       "plan": "shaper",
      //       "radars": 97,
      //       "members": 292,
      //       "visitors": 98,
      //       "collaborationTools": 1,
      //       "wall": 0
      //     },
      //     "settings": null,
      //     "urlLoginEnabled": 1
      //   },
      //   {
      //     "id": 56,
      //     "label": "FP M1",
      //     "usersAllowed": 300,
      //     "visitorsAllowed": 100,
      //     "radarsAllowed": 100,
      //     "usersUsed": 8,
      //     "radarsUsed": 3,
      //     "radarLogoEnabled": 1,
      //     "accountPermissions": {
      //       "role": "fp-visitor",
      //       "permissions": [
      //         "view group",
      //         "view radars page"
      //       ]
      //     },
      //     "accountDrupalRoles": [
      //       [
      //         "authenticated"
      //       ]
      //     ],
      //     "availableResources": {
      //       "plan": "shaper",
      //       "radars": 97,
      //       "members": 292,
      //       "visitors": 98,
      //       "collaborationTools": 1,
      //       "wall": 0
      //     },
      //     "settings": null,
      //     "urlLoginEnabled": 1
      //   },
      //   {
      //     "id": 56,
      //     "label": "FP M1",
      //     "usersAllowed": 300,
      //     "visitorsAllowed": 100,
      //     "radarsAllowed": 100,
      //     "usersUsed": 8,
      //     "radarsUsed": 3,
      //     "radarLogoEnabled": 1,
      //     "accountPermissions": {
      //       "role": "fp-visitor",
      //       "permissions": [
      //         "view group",
      //         "view radars page"
      //       ]
      //     },
      //     "accountDrupalRoles": [
      //       [
      //         "authenticated"
      //       ]
      //     ],
      //     "availableResources": {
      //       "plan": "shaper",
      //       "radars": 97,
      //       "members": 292,
      //       "visitors": 98,
      //       "collaborationTools": 1,
      //       "wall": 0
      //     },
      //     "settings": null,
      //     "urlLoginEnabled": 1
      //   }
      // ]
      const groupNormalized = 
      !!getGroupByIdData && !!getGroupByIdData?.length && 
        await Promise.all(
          getGroupByIdData?.map((group) => {

            return normalizeGroup(group)
        }))

      dispatch({
        type: ACTIONS.CONTENTSETTINGS,
        payload: groupNormalized
      })

    return {
      phenomenon, phenomenonType, crowdsourcedData, allCmtsData, 
      // newsFeed,
      getPhenomenaData, groupNormalized, getRadarData
    }   
     } catch (error) {
      
     }
  })
  !!data && NProgress.set(0.5)
  const filteredByValue = React.useMemo(() => {
    return (!!data?.crowdsourcedData) && Object.fromEntries(
      Object.entries(data?.crowdsourcedData?.data).filter(([key, value]) => key?.toString() === pid?.toString()) )
  }, [JSON.stringify(data?.crowdsourcedData), pid])

  const phenomenonData = React.useMemo(() => {

    return data?.phenomenon?.data
  }, [JSON.stringify(data?.phenomenon?.data)])

  const langRadar = React.useMemo(() => {
    return data?.phenomenon.data?.language
  }, [data?.phenomenon?.data?.language])

  const phenomenonTypesData = React.useMemo(() => {
    return data?.phenomenonType
  }, [JSON.stringify(data?.phenomenonType)])

  const phenomenonTypeData = React.useMemo(() => {
    return (!!data?.phenomenonType?.length && !!data?.phenomenon?.data?.content?.type) ? data?.phenomenonType?.filter(type => type?.id === data?.phenomenon?.data?.content?.type) : []
  }, [JSON.stringify(data?.phenomenonType), JSON.stringify(data?.phenomenon)])

  const canEditContentCard = React.useMemo(() => {
    if( !!gid && Number(gid) === 0) {
      const IsHaveCanEditPublic = !!data?.groupNormalized?.length && data?.groupNormalized?.filter((gr) => {
        return gr?.canEditPublic === true
      })
      return IsHaveCanEditPublic?.length > 0 ? true : false
    } else {
      const isHaveCanEdit = !!data?.groupNormalized?.length && data?.groupNormalized?.filter((gr) => {
        return gr?.canEdit === true
      })
      return isHaveCanEdit?.length > 0 ? true : false
    }
  // }, [JSON.stringify(data)])
}, [JSON.stringify(data?.groupNormalized), gid, pid])

  const {data: data2} = useSWR((!!data)
    ? `phenNews-relatedPhen-latestEditor=${JSON.stringify(data)}/${JSON.stringify(phenomenonData)}`
      : null,
    async () => {
      const [phenomenonNewsRes, relatedPhenomenaRes, latestEditorName] = await Promise.all(
        [
          !!phenomenonData?.newsFeeds?.length && getPhenomenonNewsByPhenId(phenomenonData?.newsFeeds?.map(feed => feed?.id), [Number(gid)]),
          !!data?.phenomenon?.data?.content?.related_phenomena?.length && getPhenomena({'phenomena': data?.phenomenon?.data?.content?.related_phenomena, undefined, groups: [0, Number(gid)]
            // , size: data?.phenomenon?.data?.content?.related_phenomena.length
          }),
          (Number(gid) !== 0) && !!data?.getPhenomenaData?.result?.length && votingApi.getUserName(data?.getPhenomenaData?.result[0]?.updated_by)
        ]
      )

      return {
        phenomenonNewsRes, relatedPhenomenaRes, 
        latestEditorName
      }
  })

  const onOpenPhenEditCard = () => {
    setOpenPhenEditCard(true)
  }

  const onClosePhenEditCard = () => {
    setOpenPhenEditCard(false)
  }

  const relatedPhenomenaData = React.useMemo(() => {
    const newRelated_content  = !!data2?.relatedPhenomenaRes?.result?.length && data2?.relatedPhenomenaRes?.result?.sort(function(a, b) {
      return data?.phenomenon?.data?.content?.related_phenomena?.indexOf(a?.id) - data?.phenomenon?.data?.content?.related_phenomena.indexOf(b?.id);
    });
    const relatedContentsFiltered =  !!data2?.relatedPhenomenaRes?.result?.length && newRelated_content?.map((r_phen) => {
      const a =  !!data?.phenomenonType?.length && data?.phenomenonType?.filter(type => type.id === r_phen?.content?.type)

      if (!!a?.length && !!a[0] && a[0]?.style && !!a[0]?.style?.color && !!a[0]?.groupType) {
        r_phen['color'] = a[0]?.style?.color
        r_phen['content-type-alias'] = 'undefined'
        r_phen['content-type-title'] = a[0]?.title || a[0]?.label
      } else {
        r_phen['color'] = 'none'
        r_phen['content-type-alias'] = a[0]?.alias
        r_phen['content-type-title'] = a[0]?.title

      }
      return r_phen
    })

    return relatedContentsFiltered
  }, [data, data2])

  const phenNews = React.useMemo(() => {
    return data2?.phenomenonNewsRes
  }, [data, data2])

  const statictis = React.useMemo(() => {
    return filteredByValue[pid]
  }, [JSON.stringify(filteredByValue), pid])

  const completedPhenomenon = React.useMemo(() => {
    let phenTemp = {...phenomenonData?.content} ?? {}
    phenTemp['crowdsourced'] = (!!statictis) ? String(statictis?.['year_median'])?.split('.')[0] :  null
    phenTemp['color'] = (!!phenomenonData && !!phenomenonTypeData && !!phenomenonTypeData?.length && !!phenomenonTypeData[0]?.style?.color && phenomenonData?.content?.type?.includes('fp:doc-types')) 
      ? (phenomenonTypeData[0]?.style?.color || 'none'): 'none'
    if (phenTemp['color'] === 'none' && !phenomenonTypeData?.length && !!phenomenonData?.content?.type?.includes('fp:doc-types')) {
      phenTemp['content-type-alias'] = "undefined"
      phenTemp['content-type-title'] = "(no type)"
    } else {
      phenTemp['content-type-alias'] = phenomenonTypeData && !!phenomenonTypeData?.length && phenomenonTypeData[0]?.alias
      phenTemp['content-type-title'] = phenomenonTypeData && !!phenomenonTypeData?.length && phenomenonTypeData[0]?.title
    }
    phenTemp['related-phenomena-data'] = relatedPhenomenaData ? relatedPhenomenaData : null
    phenTemp['phen-data'] = {...phenomenonData}
    phenTemp['phenomenonType'] = !!phenomenonTypesData && [...phenomenonTypesData]
    phenTemp['links'] = !!phenomenonData?.content?.links ? [...phenomenonData?.content?.links] : null
    
    // phenTemp['newsFeeds'] = newsFeed
    phenTemp['newsFeeds'] = phenomenonData?.newsFeeds

    phenTemp['tags'] = data?.getPhenomenaData?.result[0]?.['tags_fp:docs/props/has'] || data?.getPhenomenaData?.result[0]?.['tags']
    phenTemp['group'] = !!phenomenonData?.groups?.length && phenomenonData?.groups[0]
    dispatch({
      type: ACTIONS.TAGS,
      payload: phenTemp?.['tags']
    })
    return phenTemp
  }, [data, data2, phenomenonData, phenomenonTypeData, relatedPhenomenaData, phenomenonTypesData, statictis])

  useEffect(() => {
    dispatch({
      type: ACTIONS.CONTENTCARDDATA,
      payload: {...completedPhenomenon}
    })
  }, [completedPhenomenon, dispatch])

  const ratingsOn = React.useMemo(() => {
    if (!!data?.getRadarData?.ratingsOn) {
      return data?.getRadarData?.ratingsOn
    } 
    return false
  }, [data?.getRadarData?.ratingsOn])

  const likingOn = React.useMemo(() => {
    if (!!data?.getRadarData?.likingOn) {
      return data?.getRadarData?.likingOn
    } 
    return false
  }, [data?.getRadarData?.likingOn])

  const commentsOn = React.useMemo(() => {
    if (!!data?.getRadarData?.commentsOn) {
      return data?.getRadarData?.commentsOn
    } 
    return false
  }, [data?.getRadarData?.commentsOn])

  const votingOn = React.useMemo(() => {
    if (!!data?.getRadarData?.votingOn) {
      return data?.getRadarData?.votingOn
    } 
    return false
  }, [data?.getRadarData?.votingOn])
  !!data && !!completedPhenomenon && NProgress.done(true)
  !!data && !!completedPhenomenon && NProgress.remove()
  return (
    <SWRConfig value={{ provider: () => new Map() }}>

      <div>
            {
              !!data && (
                <div className="container p_4" style={{
                  'background': '#fff',
                  color: '#333',
                  /* padding: 30px 40px 40px; */
                  paddingLeft: '5%',
                  paddingRight: '5%',
                  // paddingBottom: '4.5%',
                  paddingTop: '1rem' 
                }} >
                  <CardHeader position={position} gid={gid} rid={rid} pid={pid} phenomenon={completedPhenomenon} lang={langRadar} votingOn={votingOn}/>
                  <YoutubeImageEmbed embedId="rokGy0huYEA" phenomenon={completedPhenomenon} />
                  <LatestNewsCarousel className="latestNewsCarousel-content-card" lang={langRadar} phenNews={phenNews}/>
                  {
                    !!rid && position === 'left' && !!ratingsOn && (
                      <Rating gid={gid} rid={rid} pid={pid} lang={langRadar} getRadarData={data?.getRadarData}/>
                    )
                  }
                  {
                    (!!rid && position === 'left') && (
                      <Comments gid={gid} rid={rid} pid={pid} lang={langRadar} phenomenon={completedPhenomenon}
                        commentsOn={commentsOn}
                        likingOn={likingOn}
                      />
                    )
                  }
                  <BodyCard phenomenon={completedPhenomenon} />
                  {
                    !!completedPhenomenon?.links?.length && (
                      <Links phenomenon={completedPhenomenon} lang={langRadar}/>
                    )
                  }
                  <RelatedPhenomena className="relatedPhenomena-content-card" phenomenon={completedPhenomenon} gid={gid} rid={rid} pid={pid} lang={langRadar}/>
                  <LatestNews className="latestNews-content-card" phenNews={phenNews} lang={langRadar}/>
                  <CardFooter
                    data={data}
                    lang={langRadar} 
                    onOpenPhenEditCard={onOpenPhenEditCard}
                    canEditContentCard={canEditContentCard}
                    gid={gid}
                    lastesEditor={data2?.latestEditorName?.data?.displayName}
                  />
                  {!!openPhenEditCard && (
                  <Modal
                    isOpen={openPhenEditCard}
                    onRequestClose={onClosePhenEditCard}
                    style={PhenEditCardStyles}
                    ariaHideApp={false}
                  >
                    <PhenomenonEditForm
                      radar={completedPhenomenon}
                      gid={gid}
                      rid={rid}
                      pid={pid}
                      urlSWR={`getAllCommentsByPhenId=${groupRadarId || gid}/${rid}/${pid}`}
                      mutateContentCard={mutateContentCard} onSubmit={() => alert("done")} basePhenomenon={{}} onCancel={onClosePhenEditCard}/>
                  </Modal>
                  )}
                </div>
              )
            }
          </div>
    </SWRConfig>
  )
}
export default App;

// const AddToRadarButton = styled.button`
//   height: 22px;
//   width: 48px;
//   font-size: 11px;
// `;