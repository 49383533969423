import React from "react";
import _ from "lodash";
import { Tag } from "@futures-platform/ui";
// import Tag from './Tag_sangre_ui'
import { useTags } from "@futures-platform/hooks";
import { useSelector } from "react-redux";
export const PhenomenaTagList = ({
  language,
  phenomena,
  tagList,
  groupTags,
  fpTags,
  tags,
  isEditMode
}) => {
  

  if (!tagList) {
    return null;
  }

  
  return (
    <div className="d-flex flex-row flex-wrap">
      {/* { renderPhenomenaTags && tags?.map((tagUri, index) => {
          const tagObj = _.find(!!tagList && !!tagList?.length && tagList[0], ({ uri }) => uri === tagUri) || _.find((!!tagList && tagList?.length > 1 && !!tagList[1]?.length) ? tagList[1] : (!!tagList2 && tagList2?.length > 1 && tagList2[1]), ({uri }) => uri === tagUri)
          if (!tagObj) {
            return null
          }
          const label = _.isString(tagObj.label) ? tagObj.label : tagObj.label[lang]
          const tagUriSplit = tagUri?.split("/")
          const isFPTags= tagUriSplit[1] === "theme" ? true : false
          return (
            <Tag key={index} active onClick={null} label={label} small isFPTags={isFPTags} isNotFilter={true} />
          )
        })} */}
      {groupTags?.length > 0 &&
        groupTags
          ?.sort(function (a, b) {
            const textA = a.label.toUpperCase();
            const textB = b.label.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
          ?.map((tag, index) => {
            return (
              <Tag
                key={index}
                active
                onClick={null}
                label={tag?.label}
                small
                isFPTags={tag?.isFPTags}
                isNotFilter={true}
              />
            );
          })}
      {fpTags?.length > 0 &&
        fpTags
          ?.sort(function (a, b) {
            const textA = a.label.toUpperCase();
            const textB = b.label.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
          ?.map((tag, index) => {
            return (
              <Tag
                key={index}
                active
                onClick={null}
                label={tag?.label}
                small
                isFPTags={tag?.isFPTags}
                isNotFilter={true}
              />
            );
          })}
    </div>
  );
};
