import React, { createContext, useReducer } from 'react'
import reducers from './Reducers.js'
import {commentingApi} from '../helpers/commentingFetcher'
import {ACTIONS} from './Actions'
import { getUserId } from '@futures-platform/connectors/session'
import useSWR, { useSWRConfig } from 'swr'

const initialState = {
    cmtsData: [],
    contentData:{},
    contentSettings:{},
    contentTypes: [],
    groupRadarIdGlobalState: null
}

export const DataContext = createContext(initialState) 

export const DataProvider = ({children, gid, rid, pid}) => {
    const [state, dispatch] = useReducer(reducers, initialState)

    return(
        /* eslint-disable */
        <>
            <DataContext.Provider value={{state, dispatch}}>
                {children}
            </DataContext.Provider>
        </>
    )
}

