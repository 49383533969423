import _ from 'lodash'
import React, {useContext} from 'react'
import styled, { withTheme } from 'styled-components'
import { useTags } from '@futures-platform/hooks'
import { Tag } from '@futures-platform/ui'
// import Tag from '../components/Tag_sangre_ui'
import { requestTranslation } from '@futures-platform/i18n'
import { useDispatch } from 'react-redux'
import {capitalizeFirstLetter} from "../../helpers/helpers"
import {tagPhenomenon, removeTagPhenomenon} from '@futures-platform/connectors/tag-service-api'
import {getPhenomena, getContentCardData, getPhenomenonNewsByPhenId, getPhenomenaStatistic, getNewsFeedData} from '../../helpers/phenomenonFetcher'
import {DataContext} from '../../store/GlobalState'
import { ACTIONS } from '../../store/Actions'
import { useSWRConfig } from 'swr'

const ELEMENT_WIDTH = 280
const FP_TOPBAR_OFFSET = process.env.NODE_ENV === 'development' ? 0 : 112


export const PhenomenaTagSelector = ({gid, rid, pid, mutateContentCard, phenomenon, tagList, groupTags, fpTags, lang, mutateTaglist}) => {

  const { refreshInterval, mutate, cache, ...restConfig } = useSWRConfig()
  const { state: {tags: activeTags}, dispatch } = useContext(DataContext)

 
  const checkTagStatus = tag => {
    // const { tags } = phenomenon

    let found = false

    if (!activeTags || !activeTags.length) {
      return found
    }

    activeTags.map(t => {
      if (_.isEqual(t, tag.uri)) {
        found = true
      }
    })

    return found
  }

  // console.log('elmtRef', elmtRef.current)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dragElement = (elmnt) => {

    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (!!document?.getElementById("tagging-selector-header")) {
      /* if present, the header is where you move the DIV from:*/
      document.getElementById("tagging-selector-header").onmousedown = dragMouseDown;
    } 
    else {
      // console.log(222)
      // /* otherwise, move the DIV from anywhere inside the DIV:*/
      // elmnt.onmousedown = dragMouseDown;
    }
    
    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }
  
    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
      elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    }
  
    function closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }

  // // eslint-disable-next-line react-hooks/rules-of-hooks
  // const groupsActiveNumber = !!groupTags?.length && groupTags.filter((tag) => !!checkTagStatus(tag)) || []
  // // eslint-disable-next-line react-hooks/rules-of-hooks
  // const fpActiveNumber = !!fpTags?.length && fpTags.filter((tag) => !!checkTagStatus(tag)) || []

  // console.log('groupsActiveNumbergroupsActiveNumber', groupsActiveNumber, fpActiveNumber)
  // // eslint-disable-next-line react-hooks/rules-of-hooks
  // const totalTagsActive = React.useMemo(() => {
  //   return Number(fpActiveNumber?.length) + Number(groupsActiveNumber?.length)
  // }, [groupsActiveNumber, fpActiveNumber])
  // let postionTop = 0
  // if (totalTagsActive < 1 || !totalTagsActive) {
  //   postionTop = '70%'
  // } else if (totalTagsActive <= 7 && totalTagsActive >= 1 ) {
  //   postionTop = '67%'
  // }else if (totalTagsActive > 7 ) {
  //   postionTop = '65%'
  // } 
  // else if ( totalTagsActive >15 ) {
  //   postionTop = '63%'
  // } 
  // else if (totalTagsActive <= 25 && totalTagsActive >20 ) {
  //   postionTop = '61%'
  // } else {
  //   postionTop = '59%'
  // }

  // todo : for movable the tag-selector
  // !!isInEditMode && document?.getElementById("tag-selector-modal") && dragElement(document.getElementById("tag-selector-modal"));

  // const isSafariOnMobile = (!!navigator && navigator?.vendor && navigator?.vendor.indexOf('Apple') > -1 && navigator?.userAgent && navigator?.userAgent.indexOf('CriOS') == -1 && navigator?.userAgent.indexOf('FxiOS') == -1)
  //   && (/webOS|iPhone|iPad|iPod/i.test(navigator.userAgent) === true)

  return (
    <div style={{ zIndex: 999999 }}>
      {/* {
        !isInEditMode && (
          <Thumb style={{ left: x - 22 + 'px', top: y - FP_TOPBAR_OFFSET + window.scrollY + 'px' }} />
        )
      } */}
      
      <Padding
          id="tag-selector-modal"
          style={{ 
            zIndex:  999999, 
            // left: x - ELEMENT_WIDTH - 25 + window.scrollX + 'px',
            // // eslint-disable-next-line no-restricted-globals
            // top: y - FP_TOPBAR_OFFSET - 40 + window.scrollY + 'px',
            left:   ELEMENT_WIDTH  + window.scrollX +'px',
            // top:  FP_TOPBAR_OFFSET  + window.scrollY -200 + 'px',
            top:  1555 + 'px',

            padding: 0,
            position: 'absolute'
            // marginLeft: !!isInEditMode ? '15px' : 'unset'
      }}>
        {/* <div id = 'tagging-selector-header' 
        style={{background: 'white', position:'unset', width:'100%', padding:'10px'}}>
          <p> Add/remove tags</p>
          <div id="tagging-selector-header-content" style={{marginTop: '12px'}}>You can now select
           the most suitable tags to the content.
           When done, please remember to SAVE your work!
          </div>

        </div> */}
        <ListContainer>
          
          {/* <div style={{width: `${ELEMENT_WIDTH + 40}px`}}> */}
          {/* {
            <Thumb 
              // style={{ left: x - 1088 + 'px', top: y - FP_TOPBAR_OFFSET + window.scrollY - 500 + 'px' }} 
                style={{ right: `-12px`, top:`42px` }} 

              />
          } */}
          {/* {loading && (<div className='pl-2'>{requestTranslation('loading')}</div>)}
          {error && (<div className='pl-2'>{requestTranslation('tagLoadingError')}</div>)} */}
          {!!tagList?.length && (
            <div style={{position: 'relative'}}>
             
              <div id='tagging-selector-header'   
                // cursor: 'move', 
                // position: !isInEditMode ? 'absolute' : 'unset',
                // background: !!isInEditMode ? 'white' : 'unset',
                // width: !!isInEditMode ? '100%': 'unset',
                // height: !!isInEditMode ? 'fit-content' : 'unset',
                // padding: !!isInEditMode ? '10px' : 'unset',
                // height: !!isInEditMode ? '-moz-max-content' : 'unset',    /* Firefox/Gecko */
                // height: !!isInEditMode ? '-webkit-max-content' : 'unset'/* Chrome */
                style={{background: 'white', position:'unset', width:'100%', padding:'10px'}}
              >
                  <p> Add/remove tags</p>
              <div id="tagging-selector-header-content" style={{marginTop: '12px'}}>You can now select
           the most suitable tags to the content.
           When done, please remember to SAVE your work!
          </div>
               {
                <>
                <p style={{fontWeight:'bold'}}>{requestTranslation('TitleTaggingSelector')}</p> 
                 <div id='tagging-selector-header-content' style={{marginTop:'12px'}}>
                   {requestTranslation('AskingSaveTagsInEditorMode')}
                 </div>
              </>
               }
              </div>
              {!!(tagList[1] && tagList[1].length) && <TagLabel className='mb-0 ml-2'>{requestTranslation('groupTags')}</TagLabel>}
              <div className={`d-flex flex-wrap flex-row ${tagList[1] && tagList[1].length ? 'mb-4' : ''}`}>
                {!!tagList[1] && !!tagList[1]?.length ? tagList[1]
                   ?.sort(function (a, b) {
                      const textA = a?.label?.toUpperCase();
                      const textB = b?.label?.toUpperCase()
                      return textA < textB ? -1 : textA > textB ? 1 : 0;
                    })
                  .map((tag, index) => {
                  const isActive = checkTagStatus(tag)
                    
                  return (
                        <>
                          {
                            !!phenomenon?.['phen-data']?.groups?.length && !!phenomenon?.['phen-data']?.id  && (
                              <OptionsListItem key={index}>
                                <Tag
                                  isFPTags={false}
                                  isNotFilter={true}
                                  label={capitalizeFirstLetter(tag.label)}
                                  active={isActive}
                                  onClick={async () => {
                                    try {
                                      (!isActive) 
                                      ? await tagPhenomenon(
                                          !!phenomenon?.['phen-data']?.groups?.length && phenomenon?.['phen-data']?.groups[0],
                                          !!phenomenon?.['phen-data']?.id && phenomenon?.['phen-data']?.id,
                                          tag?.uri
                                        ) 
                                      : await removeTagPhenomenon(
                                        !!phenomenon?.['phen-data']?.groups?.length && phenomenon?.['phen-data']?.groups[0],
                                        !!phenomenon?.['phen-data']?.id && phenomenon?.['phen-data']?.id,
                                        tag?.uri
                                      )

                                    let tagSelectorList = [...activeTags]
                                      let tagSelectorListRm= []
                                      if ( !isActive) {
                                        tagSelectorList?.push(tag?.uri)
                                      } else {
                                        tagSelectorListRm = tagSelectorList?.filter((t) => {
                                          return t !== tag?.uri
                                        })
                                      }
                                      dispatch({
                                        type: ACTIONS.TAGS,
                                        payload: !isActive ? tagSelectorList : tagSelectorListRm
                                      })

                                      mutateContentCard(data => ({...data, 
                                        getPhenomenaData: {...data.getPhenomenaData, 
                                          result: [
                                            {
                                              ...data.getPhenomenaData.result[0],
                                              tags: !isActive ? tagSelectorList : tagSelectorListRm,
                                              'tags_fp:docs/props/has': !isActive ? tagSelectorList : tagSelectorListRm
                                            }
                                      ]}}), false)
                                    } catch (error) {
                                      
                                    }
                                  }}
                                />
                              </OptionsListItem>
                            )
                          }
                        </>
                  )
                }) : null}
              </div>
              <TagLabel className='mb-0 mt-2 ml-2'>{requestTranslation('fpTags')}</TagLabel>
              <div className='d-flex flex-wrap flex-row'>
                {!!tagList[0] && !!tagList[0]?.length && tagList[0]
                  ?.sort(function (a, b) {
                    const textA = !!a?.label[lang] ? a?.label[lang].toUpperCase(): a?.label?.toUpperCase();
                    const textB = !!b?.label[lang] ? b?.label[lang].toUpperCase(): b?.label?.toUpperCase();
                    return textA < textB ? -1 : textA > textB ? 1 : 0;
                  })
                  ?.map((tag, index) => {
                  const isActive = checkTagStatus(tag)

                  return (
                    <OptionsListItem key={index}>
                        <Tag
                          label={tag.label[lang] ? capitalizeFirstLetter(tag.label[lang]) : capitalizeFirstLetter(tag.label)}
                          isFPTags={true}
                          isNotFilter={true}
                          active={isActive}
                          onClick={async () => {
                            try {
                              (!isActive) 
                              ? await tagPhenomenon(
                                  !!phenomenon?.['phen-data']?.groups?.length && phenomenon?.['phen-data']?.groups[0],
                                  !!phenomenon?.['phen-data']?.id && phenomenon?.['phen-data']?.id,
                                  tag?.uri
                                ) 
                              : await removeTagPhenomenon(
                                !!phenomenon?.['phen-data']?.groups?.length && phenomenon?.['phen-data']?.groups[0],
                                !!phenomenon?.['phen-data']?.id && phenomenon?.['phen-data']?.id,
                                tag?.uri
                              )

                            let tagSelectorList = [...activeTags]
                              let tagSelectorListRm= []
                              if ( !isActive) {
                                tagSelectorList?.push(tag?.uri)
                              } else {
                                tagSelectorListRm = tagSelectorList?.filter((t) => {
                                  return t !== tag?.uri
                                })
                              }
                              dispatch({
                                type: ACTIONS.TAGS,
                                payload: !isActive ? tagSelectorList : tagSelectorListRm
                              })

                              mutateContentCard(data => ({...data, 
                                getPhenomenaData: {...data.getPhenomenaData, 
                                  result: [
                                    {
                                      ...data.getPhenomenaData.result[0],
                                      tags: !isActive ? tagSelectorList : tagSelectorListRm,
                                      'tags_fp:docs/props/has': !isActive ? tagSelectorList : tagSelectorListRm
                                    }
                              ]}}), false)
                            } catch (error) {
                              
                            }
                          }}
                        />
                    </OptionsListItem>
                  )}
                )}
              </div>
            </div>
          )}
          {/* </div> */}
       
        </ListContainer>
      </Padding>
    </div>
  )
}

const ListContainer = styled.div`
  overflow: auto;
  resize: both;
  min-height: 50px;
  min-width: 50px;
  width: ${ELEMENT_WIDTH}px;
  height: 300px;
  max-width: 700px;
  max-height: 700px;
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #ECECEC;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-track {
    background: white;
    border-radius: 20px;
  }
`

const Thumb = styled.div`
  // background: #F6F4F7;
  background: linear-gradient(to top right, transparent 50%,#F6F4F7 50%);
  width: 25px;
  height: 25px;
  transform: rotate(45deg);
  /*box-shadow: 0 1px 2px 0 rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.05);*/
  position: absolute;
  z-index: 9;
`

const Padding = styled.div`
  /*box-shadow: 0 1px 2px 0 rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.05);*/
  padding: 15px 8px;
  position: absolute;
  background: #F6F4F7;
  border-radius: 2px;
  z-index: 10;
`

const TagLabel = styled.div`
    font-size: 12px;
    color: #667585;
`

const OptionsListItem = styled.div`
    padding: 0 5px;
    display: flex;
    align-items: center;
    margin-top: 8px;
`
